import { useContext } from "react";
import { Link } from "react-router-dom";
import Social from "../Social";
import { ROUTES, routeTree } from "@routes";
import { Context } from "@root";

// #b78511
// #77801a

const NavItem = ({ item }) => {
  return (
    <div className={navItem}>
      <div className="mb-1">
        <Link to={item.link}>{item.display}</Link>
      </div>
    </div>
  );
};
export default function Header() {
  const { displayHeaderFooter } = useContext(Context);
  const displayNav = displayHeaderFooter;

  return (
    <div className={container}>
      <div className={mainLinkContainer}>
        <Link to={ROUTES.home} className={siteName}>
          Moonflamelove.
        </Link>
        {displayNav &&
          routeTree.map((item, i) => <NavItem item={item} key={i} />)}
      </div>
      <div className={secondaryLinkContainer}>
        <div className={socialLinkContainer}>
          <Social size="lg"/>
        </div>
        <div className={WWMCTA}>
          <Link to={ROUTES.contact}>{'Contact'}</Link>
        </div>
      </div>
    </div>
  );
}

// #4f2936 - purple
const container =
  "flex w-full justify-between items-center text-black fixed bg-[white] top-0 pt-4 font-sans z-10";
const mainLinkContainer = "w-5/7 my-2 flex items-center ml-10";
const secondaryLinkContainer = "flex -mt-2 flex-nowrap items-center justify-between"
const socialLinkContainer = "flex w-[10%] flex justify-between";

const siteName = "font-bold text-2xl mb-2";
const navItem = "ml-10 px-2 capitalize relative group cursor-pointer";
export const WWMCTA =
  "bg-black text-[white] ml-5 mr-10 py-3 px-10 rounded-md hover:scale-[0.97] font-bold tracking-wider hover:cursor-pointer shadow-xl";
