import portrait from "../../assets/violet/main-profile.jpeg";

export default function Principles() {
  return (
    <div className={"w-full flex h-auto py-10 bg-black text-[white] mt-10"}>
      <div className="text-1xl h-full flex flex-col w-1/2 justify-center items-center h-[500px]">
        <div className="max-w-[500px]">
          <div className="font-bold text-6xl mb-8 text-center">Principles</div>
          <li className="font-bold mt-4">We are interconnected.</li>
          <li className="font-bold mt-4">
            Intuition knows authenticity; we find each other.
          </li>
          <li className="font-bold mt-4">
            You are the author and expert of your experience.
          </li>
          <li className="font-bold mt-4">
            Creativity and curiosity offer a powerful way back to Self.
          </li>
          <li className="font-bold mt-4">
            Your sensitivity is beautiful and world-saving.
          </li>
          <li className="font-bold mt-4">There is peace. There is love.</li>
          <li className="font-bold my-4">Empathy heals.</li>
        </div>
      </div>
      <div className="flex justify-center items-center w-1/2">
        <img src={portrait} className="h-[500px] shrink-0 " alt={""} />
      </div>
    </div>
  );
}
