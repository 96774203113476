import React from "react";
import { Achievements, Bio, Approach, Impact, Background } from "./components";

export default function AboutMe() {
  return (
    <div className="flex flex-col pb-10">
      <Bio />
      <Impact />
      <Approach />
      <Achievements />

      <iframe
        height="200px"
        width="100%"
        className="my-20"
        title="professional podcast network interview with violet moon from moonflamelove"
        frameborder="no"
        scrolling="no"
        seamless
        src="https://player.simplecast.com/68c6ce68-17c3-4684-ba01-320fa21e20fe?dark=false"
      ></iframe>
      <Background />
    </div>
  );
}

export const section =
  "flex items-center justify-center h-[600px] mb-20 last:mb-0";
export const header = "capitalize font-bold text-4xl mb-8 text-center";
