export default function ReducedRateNotice() {
  return (
    <div className="w-full mb-10 bg-black p-10 text-[white] flex flex-col justify-center items-center">
      <div className="font-bold text-xl mb-4 text-center">
        Reduced Rate Notice
      </div>
      <div className="max-w-[800px]">
        I offer two reduced rate options for "Standard Guide" sessions{" "}
        <span className="font-bold">($135 and $115)</span>. No questions asked
        should you request one. However, there are only a few available spots at
        any given time, and then a waitlist. So knowing your situation,
        capability, access to resources, and overall intersectional relationship
        to the Western capitalist system, I ask that you please request in
        authenticity, alignment, and grace.
      </div>
      <a
        href="https://forms.gle/JD6beYxDSGvHRuyU7"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full flex justify-center"
      >
        <div className="hover:bg-creme/40 bg-creme hover:text-[white] w-1/4 text-center text-black px-10 py-2 mt-6 cursor-pointer rounded-sm">
          Add Me To The Waitlist
        </div>
      </a>
    </div>
  );
}
