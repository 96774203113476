import React from "react";

export default function Header() {
  return (
    <div className={"mb-10"}>
      <div className="flex lg:flex-row flex-col items-center">
        <div className="lg:w-1/2 flex items-center h-[600px] w-full mr-8">
          <div
            className="bg-vega-outside h-full w-full bg-cover bg-center mr-2 shadow-md"
            alt="violet sitting below paintings with arms open resting on back of couch"
          ></div>
        </div>
        <div className="lg:pr-10 lg:w-1/2">
          <div className="text-4xl font-bold">Disclaimer*</div>
          <div className="text-xl text-red font-bold uppercase">important</div>

          <div className="pt-8">
            While I am a dedicated mental health professional with expertise in
            clinical therapy, it's important to note that{" "}
            <strong>
              the separate healing services offered here are distinct
            </strong>{" "}
            and{" "}
            <strong>
              conducted outside the scope of my clinical therapy practice
            </strong>
            . These sessions are not a substitute for formal therapeutic
            interventions, and they serve a different purpose.
          </div>
          <div className="mt-4">
            Please be aware that{" "}
            <strong>
              engaging in these separate healing services does not establish a
              therapist-client relationship
            </strong>
            , and they{" "}
            <strong>should not be considered a form of clinical therapy</strong>
            . If you require clinical therapy, it is recommended to seek the
            services of a licensed mental health professional in your
            jurisdiction.
          </div>
          <div className="mt-4">
            If you live in the state of Texas and would like to work with me in
            a formal, clinical capacity, I work as a{" "}
            <a
              href="https://chillcounseling.com/violet/"
              rel="noreferrer"
              target="_blank"
              className="font-bold"
            >
              Counselor Intern supervised by Cori Hill LPC, LMFT at Chill
              Counseling
            </a>
            .
          </div>
          {/* <div className="mt-4">
            Otherwise, for all other sessions as mentioned in the numbered
            sections above, you are welcome and warmly invited to book a free 15-minute
            consulation call.
          </div>
          <div className="mt-8 flex">
            <div className={primaryBtn}>
              <Link to={ROUTES.contact}>Book A Free Consult Call</Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

/* <div className={header}>Working with me</div>
<div className="mt-4">
  In my practice, you will always have the authority and autonomy to
  decide if any (or none of) my offerings are something you want to
  try. Your life is your own here. You get to design the path that
  feels most aligned with where you want to go.
</div>
<div className="mt-4">
  My role is to offer containment. I am here to hold you as you make
  your way back to yourself. In all the ways you have felt afraid,
  ashamed, unforgiveable, worthless, not enough, or unloveable— I am
  here to not only see you, and witness this fear.
</div>
<div className="mt-4 font-bold">
  I am here to reflect the powerful, brilliant, unstoppable
  unconditional in you.
</div> */
