
import portrait from "../../../assets/violet/headshot.jpg";

export default function AboutMe() {
  return (
    <div className="flex md:h-screen md:flex-row flex-col-reverse">
      <div className="md:w-1/2 flex justify-center">
        <div className="max-w-[500px] flex flex-col justify-center">
          {/* <div className={header}>About Me</div> */}
          <div className="md:text-6xl text-5xl mb-4 mt-10 md:-mt-20">
            My name is <br />
            <span className="font-bold">Violet Moon</span>.
          </div>
          <div className="mt-4 text-2xl">
            I am a fighter, feeler, and healer. I connect deeply with life and
            the things we can’t see. I walk this life curious, queer,
            genderless (they/them), and French-American. I love to hang out with my cat, walk when its raining, read stories about magic, and dance. And although I am often
            crying in the woods or praying in centuries-old cities, the ocean is my
            favorite place.
          </div>
          {/* <div className="mt-4">
            Training as a professional mental health counselor, I apply western
            study to ancient practices. I grativate towards healing built on
            centuries of collective wisdom but validate the necessity for
            security found in applied science. In the interim, I offer coaching
            and mentoring. Understanding that radical revolution of
            generational, societal, and familial systems is built when we come
            together and help one another.
          </div>
          <div className="mt-4">
            My background is made up of recovery, tech, art, and the natural
            world. I have participated in spoken word improv performances,
            outdoor healing ceremonies, wellness retreats, clinical workshops,
            psychic readings, dance intensives, and rigorous trauma care study.
            I have facilitated yoga classes and meditations. I have mentored
            software developers and eating disorder recovery groups. Sometimes I
            choreograph professional dance works, and frequently I go learn from
            places unfamiliar.
          </div>
          <div className="mt-4">
            I am evolving, awakening. And here. To meet you in the dark and find
            a way home.
          </div> */}
        </div>
      </div>
      <div className="md:w-1/2 md:h-screen flex justify-center items-center h-auto -mt-10">
        
          <img
            src={portrait}
            className="md:h-[500px] shrink-0 "
            alt={""}
          />
        
      </div>
    </div>
  );
}
