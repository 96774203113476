import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Landing, About, Services, Contact, Resources } from "@pages";
import { Nav, Footer } from "@components"
import { ROUTES } from "@routes";

// Photo by Alex Andrews: https://www.pexels.com/photo/photo-of-deep-sky-object-816608/
// Photo by Adrien Olichon: https://www.pexels.com/photo/galaxy-wallpaper-2538107/
// Photo by Daniel Schek: https://www.pexels.com/photo/photo-of-milky-way-7974310/
// Photo by Neil Yonamine: https://www.pexels.com/photo/bright-stars-in-the-outer-space-8237959/

export const Context = React.createContext();

export default function Root() {
  const location = useLocation();
  const displayHeaderFooter = location.pathname !== ROUTES.home;

  const state = {
    currentPage: location.pathname,
    displayHeaderFooter,
  };

  const rootStyles = (displayHeaderFooter) + ' h-full flex flex-col font-sans w-full text-black'
  return (
    <Context.Provider value={state}>
      <Nav />
      <div className={rootStyles}>
        <div className="md:mx-10 mx-4 mt-16">
          <Routes>
            <Route path={ROUTES.home} element={<Landing />} />
            <Route path={ROUTES.about} element={<About />} />
            <Route path={ROUTES.services} element={<Services />} />
            <Route path={ROUTES.resources} element={<Resources />} />
            <Route path={ROUTES.contact} element={<Contact />} />
          </Routes>
        </div>
        {displayHeaderFooter && <Footer />}
      </div>
    </Context.Provider>
  );
}
