import { section, header } from "../index";
import { TikTokEmbed } from "react-social-media-embed";

export default function TikToks() {
  return (
    <div className={section}>
      <div className={header}>What I talk about</div>
      <div className="overflow-scroll flex w-[1100px]">
        {tiktoks.map((url) => (
          <div className="w-[300px] mr-4">
            <TikTokEmbed
              key={url}
              url={url}
              height={500}
              width={300}
              className="mr-4"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const tiktoks = [
  "https://www.tiktok.com/@moonflamelove/video/7185953231116291371",
  "https://www.tiktok.com/@moonflamelove/video/7215629512267222315",
  "https://www.tiktok.com/@moonflamelove/video/7182672019757255982",
  "https://www.tiktok.com/@moonflamelove/video/7214268914899914027",
  "https://www.tiktok.com/@moonflamelove/video/7209565486907182382",
  "https://www.tiktok.com/@moonflamelove/video/7213080347217513771",
  "https://www.tiktok.com/@moonflamelove/video/7211554580398886190",
];
