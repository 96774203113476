export default function Tools() {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="w-full flex justify-between h-full">
        {online.map((service) => (
          <Item {...service} />
        ))}
      </div>
      <div className="h-full mt-8">
        {/* <div className="mt-4">
          <span className="underline">Walk+Talk</span>: This is the same as a
          "Guide" session except that we will be in-person and walking! Often at
          a park or through a quiet neighborhood, this is meant to encourage and
          support emotional connection (from the body to the brain and back
          again).
        </div> */}
        <div className="mb-8 font-bold">
          *Select in-person sessions are available in Houston, Texas or Paris,
          France after an initial virtual intake.
        </div>
      </div>
    </div>
  );
}

const Item = ({ title, length, location, cost, desc }) => {
  return (
    <div className="border-2 w-1/3 shadow-lg shadow-creme my-4 mr-10 p-4">
      <div className="flex justify-between">
        <div>
          <div className="first:mt-0 mt-4 text-xl font-bold">{title}</div>
          <div className="flex text-lg">
            <div>{length}</div>
            <div className="px-2 font-bold text-md">|</div>
            <div>{location}</div>
          </div>
        </div>
        <div className="rounded-full flex items-center justify-center p-2 bg-creme text-black w-[50px] h-[50px] text-sm">
          {cost}
        </div>
      </div>
      <div className="text-sm mt-4">{desc}</div>
    </div>
  );
};

const online = [
  {
    title: "Phoenix",
    cost: "$154",
    length: "50 minutes",
    location: "Video Office",
    desc: "This session type was designed to take after the traditional therapy modal using the modalities and approaches that apply to your specific experience. Although no two sessions will be the same, you can expect as a baseline to explore what's coming up and/or going on within the present. And from there, I'll create a space with you to process, feel, and expand— however that needs to happen.",
  },
  {
    title: "Crow",
    cost: "$223",
    length: "75 minutes",
    location: "Video Office",
    desc: "In this session, we navigate the same emotional, mental, and spiritual healing realm as the Phoenix session. The difference here is that we have more time. And with that time, comes possibility. For some, it's not needed. For others, it's course-altering. There's no wrong option, just what works for you.",
  },
  {
    title: "Reading",
    cost: "$67",
    length: "30 minutes",
    location: "Video Office",
    desc: "For this meeting, I will do a brief energy read and guide you through some simple grounding to attune to your question and center you to recieve what comes. I will then intuitively create a custom tarot spread for you. Followed by an offering of the wisdom revealed.",
  },
];

// const inperson = [
//   {
//     title: "Standard Walk+Talk Session",
//     cost: "$155",
//     length: "50 minutes",
//     location: "In-person*",
//   },
//   {
//     title: "Extended Walk+Talk Session",
//     cost: "$233",
//     length: "75 minutes",
//     location: "In-person*",
//   },
// ];
