const eaUrl = "https://www.ieata.org/who-we-are";
const playUrl = "https://www.a4pt.org/page/AboutAPT";
const somUrl = "https://traumahealing.org/about/";

const personcentered = "https://www.ncbi.nlm.nih.gov/books/NBK589708/";
const liberation =
  "https://www.apadivisions.org/division-24/publications/newsletters/theoretical-philosophical-digest/2021/12/liberation-psychotherapy";
const compassion = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4413786/";
// const existential =
//   "https://my.clevelandclinic.org/health/treatments/25089-existential-therapy";

export default function Background() {
  return (
    <div className="w-full">
      <div className="w-full md:p-20 p-6 bg-creme flex justify-center">
        <div className="max-w-[800px] w-full">
          <div className="text-center md:text-6xl text-4xl mt-6 md:mt-0 font-bold mb-10">Background</div>
          <div className="w-full">
            My therapy background comes from a Masters in Clinical Mental
            Health Counseling, and signifcant training in Western mental health
            practices. Within this framework, I prioritize safety and autonomy,
            fostering healing through a multually built relationship to help
            others reclaim (and often discover) self after lifetimes of
            horrific, indescribable trauma. In this work, I rely on integrating
            Western therapeutic techniques such as{" "}
            <a
              target="_blank"
              className="link"
              rel="noreferrer"
              href={personcentered}
            >
              Person-Centered
            </a>
            ,{" "}
            <a
              target="_blank"
              className="link"
              rel="noreferrer"
              href={liberation}
            >
              {" "}
              Liberation-Based
            </a>
            , and
            <a
              target="_blank"
              className="link"
              rel="noreferrer"
              href={compassion}
            >
              {" "}
              Compassion-Focused
            </a>{" "}
            therapies while pulling tools from{" "}
            <a target="_blank" className="link" rel="noreferrer" href={somUrl}>
              Somatic-Experiencing
            </a>
            ,{" "}
            <a target="_blank" className="link" rel="noreferrer" href={eaUrl}>
              Expressive Arts Therapy
            </a>
            , and{" "}
            <a target="_blank" className="link" rel="noreferrer" href={playUrl}>
              Play Therapy
            </a>{" "}
            models.
          </div>
          <div className="mt-4">
            Further, my career as a professional movement artist (performing,
            choreographing, and touring) provides me with a unique lens through
            which to understand and process emotions, trauma, and healing.
            Today, the focus of movement is about the physicality but a profound
            somatic understanding and externalization of felt human experience.
            Dancing and choreographing challenge my creativity and deepen my
            connection to the nuances of body awareness, allowing emotions and
            trauma to be felt, held, processed and released without always
            needing language.
          </div>
          <div className="mt-4">
            As both a therapist and professional dancer, my embodied
            understanding of emotion has deepened my ability to distinguish
            between intuitive insight and trauma response. It is an unique
            perspective that allows me to help clients cultivate a trusting
            relationship with their bodies and their inner voices. In session, I
            support clients in recognizing and expressing their boundaries,
            experiences, desires, and hopes to nurture personal identity and
            healing from, with, and alongside of trauma. It is an approach used
            to set the foundation for enhanced relational sessions by fostering
            genuine communication and deeper, more loving connections between
            partners.
          </div>
        </div>
      </div>
      {/* Over time, this embodied understanding of emotion has expanded my
      capacity to differentiate intuition from trauma urgency. And as a
      result, there is greater opportunity to develop internal trust to
      our body's unique language and individual soul voice so that we may
      communicate those limits, bounadries, experiences, desires, and
      hopes to our world. */}
      {/* <div className="w-full p-20 flex justify-center">
        <div className="max-w-[800px]">
          <div className="text-center text-6xl font-bold mb-10">Purpose</div>
          <div className="mt-4">
            Clinical practices are invaluable when it comes to trauma, providing
            structured methods to navigate and heal profound emotional wounds.
            However, there are moments when these conventional approaches might
            reach their limitations or no longer resonate with individuals
            seeking healing. This is where the transformative potential of what
            I do— spirit connection, intuition readings, energy clearing,
            psychic guidance, and mystic work— comes into play. These centuries
            old, culturally practiced, and generationally understood modalities
            of addressing pain extend beyond the constraints of traditional
            practices, delving into the realms where the clinical can and do
            find themselves at a loss.
            <div className="mt-4">
              It's essential to acknowledge that while clinical practices have
              their merits, they can inadvertently confine us by pathologizing
              experiences or disregarding the spiritual and intuitive dimensions
              crucial for healing. An idea understoond, respected, and practiced
              outside of colonial and puritan-enforced doctrine. Thus,
              incorporating spiritual guidance and intuitive approaches allows
              us to not only challenge (but begin to dismantle) these
              limitations by embracing a broader spectrum of healing and
              consequently personhood.
            </div>
            <div className="mt-4">
              My aim here is to bridge these gaps by incorporating the lost or
              dismissed elements that might offer deeper connections back to the
              self. Through practices like charting intentions to action, tarot
              readings, and self-made spellwork, we transcend the confines of
              clinical structures, creating a space to explore uncharted
              territories within our psyche and spirit.
            </div>
            <div className="mt-4">
              This isn't a complete dismissal of clinical practices but rather
              an acknowledgment of their limitations. It's an invitation to
              expand our healing horizons, challenge the rigid boundaries of
              clinical norms and embrace a deeper, richer, and interconnected
              approach to self. This approach recognizes the life-saving value
              of clinical practices while advocating for an integration of
              ancestral and spiritual healing, nurturing the whole-self for
              living a love-centered life rooted in peace, advocacy, peace, and
              wellness for all.
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
// <div className="mt-4">
//   When appropriate and welcomed, I also really enjoy relying on natural
//   Earth wisdom and ancestral healing practices.{" "}
//   <span className="font-bold">
//     There are non-colonial, ancient, and generationally-understood paths
//     to liberation and wellness that are equally, if not more, valuable in
//     this work
//   </span>
//   — should that feel like a desired path for you.
// </div>

// I trust that you know you better than anyone, and are
// the ultimate source of wisdom on what you need.

// <div>
//         Safety is at the center of what I do. Everything is rooted in and comes
//         back to ensuring that our relationship is one where you feel safe. This
//         means you will always have the autonomy to decide what is best for you.
//         <span className="font-bold">
//           {" "}
//           My job within our mutually cultivated relationship is to cocreate a
//           container with you to heal.
//         </span>{" "}
//         This means respecting what you've had to do to get here, celebrating who
//         you are, and orientating to where you envision. Transparency,
//         authentictiy, and empathy are at the forefront so that, from the start,
//         I am here to do what it takes to build your trust. For you to know I am
//         unequivocally and unconditionally in your corner.
//       </div>
//       <div className="mt-4">
//         In our sessions, I may pull from several studied western techniques. I
//         am linking information to each of them so you know exactly what to
//         expect. Because the tools are meant to support you. If you don't find
//         them healing, we do not use them. In a traditional talk session, this
//         can be ideas from{" "}
//         <a
//           target="_blank"
//           className="link"
//           rel="noreferrer"
//           href={personcentered}
//         >
//           Person-Centered
//         </a>
//         ,{" "}
//         <a target="_blank" className="link" rel="noreferrer" href={liberation}>
//           {" "}
//           Liberation-Based
//         </a>
//         ,
//         <a target="_blank" className="link" rel="noreferrer" href={compassion}>
//           {" "}
//           Compassion-Focused
//         </a>
//         , and{" "}
//         <a target="_blank" className="link" rel="noreferrer" href={existential}>
//           Existential
//         </a>{" "}
//         theories as well as concepts from{" "}
//         <a target="_blank" className="link" rel="noreferrer" href={somUrl}>
//           Somatic-Experiencing
//         </a>
//         ,{" "}
//         <a target="_blank" className="link" rel="noreferrer" href={eaUrl}>
//           Expressive Arts Therapy
//         </a>
//         , and{" "}
//         <a target="_blank" className="link" rel="noreferrer" href={playUrl}>
//           Play Therapy
//         </a>{" "}
//         models.
