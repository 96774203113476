export const ROUTES = {
  home: "/",
  about: "/about",
  services: "/services",
  resources: "/resources",
  contact: "/work-with-me",
};

export const routeTree = [
  {
    display: "who i am",
    link: ROUTES.about,
  },
  // {
  //   display: "how i help",
  //   link: ROUTES.services,
  // },
  {
    display: "resources",
    link: ROUTES.resources,
  }
];
