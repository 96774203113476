import React from "react";
import { Link } from 'react-router-dom'
import { ROUTES } from "../../routes";
import Social from "../Social";

export default function Footer() {
  return (
    <div className="flex bg-black w-full md:h-[200px] bottom-0 relative">
      <div className="flex md:flex-row flex-col w-full">
        <Link to={ROUTES.home} className={box + " text-2xl font-bold"}>Moonflamelove.</Link>
        <Link to={ROUTES.about} className={box}>Violet Moon, MA</Link>
        <div className={box}>Emergency Phone: 911<br/>Crisis Text: 741741</div>
        <div className={box}>
          <Social size={"lg"} color={"white"} />
        </div>
      </div>
    </div>
  );
}

const box = 'md:w-1/4 flex text-center text-[white] p-6 justify-center items-center md:border-l-[1px] md:border-l-none border-b-[1px] border-[white]/30'