import React from "react";
import one from "../../../assets/icons/1.png";
import two from "../../../assets/icons/2.png";
import three from "../../../assets/icons/3.png";
import four from "../../../assets/icons/5.png";

const icons = [one, four, three, two];

const approaches = [
  {
    title: "Compassionate",
    desc: "Throughout the process of building our relationship, trust, honesty, and openness are the cornerstones. I will be genuine in my self-expression, with the hope of creating a place for you to feel safe exploring the same. Together, we’ll find ways to celebrate what makes us human (and in a way, divine) — embracing this vulnerability as a strength as we delve into your experiences and strive towards your goals.",
  },
  // {
  //   title: "Intuitive",
  //   desc: "I do not pretend to know what you’re going through, but I will sit with you all the same. An intuitive approach with me means, I will guide and cultivate a relationship where you can trust the direction of your healing. It means that you can rely on me to hold and listen to all the parts of you, helping to build paths between the pieces that might’ve felt too gone.",
  // },
  {
    title: "Relational",
    desc: "I focus on helping individuals uncover and embrace their true selves, recognizing their inherent worth and potential in relation to others. I will cocreate paths with you from self-awareness to self-acceptance— to set the foundation for meaningful and fulfilling relationships. The hope here is that by strengthening individual identity and mutual respect, trust, and compassion— our internal AND external world becomes a safe, fulfilling place to be.",
  },
  {
    title: "Creative",
    desc: "I often pull from non-traditional methods such as nature, dance, and spirituality to address trauma at its core. This is because, often, the impact of trauma (and thus the role of healing) goes beyond the mind— so we need tools that connect us back to our body, and to our Self. Using the natural world, somatic practices, and your inherent creativity allows us to nurture deep inner healing and foster a renewed sense of connection within yourself.",
  },
  {
    title: "Spiritual",
    desc: "Acknowledging the depth of the human spirit means honoring the sacred in each person. This means cocreating an environment where spirituality becomes a powerful catalyst for healing through ancestral, psychic, and/or generational wisdom. In practice, we go beyond the cognitive and verbal to invite deeper self-exploration through the spirit practices that align with you. All with the focus to channel the inherent power, agency, and purpose already within you.",
  },
];
export default function ClickThru() {
  return (
    <div className="mb-10 flex flex-col justify-around items-center w-full">
      <div className="text-4xl font-bold my-10 py-10 bg-[white] w-full text-center">My (Life/Healing) Approach</div>
      <div className="flex justify-around flex-wrap w-full">
      {approaches.map(({ title, desc }, i) => (
        <div
          className="bg-creme w-full flex flex-col rounded-md p-10 mb-10 max-w-[600px]"
          key={title}
        >
          <div className="flex items-center mb-4">
            <img
              src={icons[i]}
              alt="hand drawn icon of abstract eye, sun and moon, and swirl"
              className="max-h-[50px] rounded-md"
            />
            <div className="text-2xl font-bold ml-2">{title}</div>
          </div>
          <div className="text-md">{desc}</div>
        </div>
      ))}
      </div>
    </div>
  );
}
