import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "@routes";
import { secondaryBtn } from "@components/constants";
import portrait from "../assets/violet/branches.jpeg";

export default function Landing() {
  return (
    <>
      <div className="flex flex-col bg-black md:hidden -mt-14">
        <FaceAndDesc />
      </div>
      <div className="flex h-[90vh] hidden w-full md:flex">
        <div className="bg-black w-full justify-center flex items-center text-[white] font-sans">
          <div className="border-2 justify-center items-center flex border-[white]/80">
            <FaceAndDesc />
          </div>
        </div>
      </div>
    </>
  );
}

function FaceAndDesc() {
  return (
    <>
      <div className="bg-black flex justify-center items-center">
        <img
          src={portrait}
          alt="portrait of violet resting their chin on their palm facing the camera in front of an orange colored moon"
          className="w-[400px] h-auto"
        />
      </div>
      <div className="flex flex-col px-10 pt-8 md:pt-0 self-center">
        <div className="w-full self-center max-w-[400px]">
          <div className="text-4xl text-[white]">Hi, I’m Violet Moon.</div>
          <div className="text-2xl mt-10 font-bold text-[white]">
            I am a love-centered, liberation-rooted pain alchemist &
            relationship healing support.
            {/* fucking help me idk what to put here. */}
            {/* I am a guide, healer, and revolutionary— combinbing ancient practices, western study, creation. Here is where we heal. */}
          </div>
          <div className="mt-10 flex md:flex-row flex-col">
            <div className={secondaryBtn}>
              <Link to={ROUTES.about}>More about me</Link>
            </div>
            <div className={secondaryBtn + " mb-10 md:mb-0"}>
              <Link to={ROUTES.resources}>Heal together</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
