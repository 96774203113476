import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from '../../routes'
import { WWMCTA } from '../../components/Nav/Header'

export default function Contact() {
  return (
    <div className="flex flex-col md:py-10">
      <div className="h-screen">
        <div className="w-full flex justify-center">
          <div className="md:max-w-[600px]">
            <div className="md:mt-10 leading-6">
              I previously served as a counselor intern at Chill Counseling
              under the supervision of Cori Hill, LPC, LMFT-S. I am now a really
              proud master's student graduate of Palo Alto University, with my
              degree in Clinical Mental Health Counseling. Currently, I am in
              the process of obtaining my licensure in the state of Texas and
              will be taking the National Counseling Exam in August. I will
              update this page with any and all next steps, once my license is obtained. Stay
              tuned for more information!
            </div>
            <div className="mt-10">
              Please note that, at this time, I am not legally able to offer
              therapy or client support. However, I will be updating a list of
              resources that you can explore for supplemental support should you
              need it. And you are welcome to check back regularly for additions on those
              resources.
            </div>
            <div className="italic mt-10 font-bold">
              If you are in an emergency or crisis in the US, please
              call 911. There is also the 24/7 crisis text-line available at
              741741.
            </div>
            <div className="mt-10">
              You're doing incredible work. Thank you for caring for you and
              liberating the collective. Be well, be kind.
            </div>
            <Link to={ROUTES.resources}>
            <div className={WWMCTA + " mt-10 text-center"}>More Resources</div>
            </Link>

          </div>
        </div>
      </div>
      {/* <div className="h-screen bg-black">
        <iframe
          title="15 minute call sign up"
          src="https://calendly.com/moonflamelove/15min"
          width="100%"
          height="100%"
          backgroundColor="black"
          frameborder="0"
        ></iframe>
      </div> */}
    </div>
  );
}

export const section =
  "flex items-center justify-center h-full mb-20 last:mb-0";
export const header = "capitalize font-bold text-4xl mb-8";
