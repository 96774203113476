import { header } from "../index";

export default function Impact() {
  return (
    <div className="text-xl mt-20 bg-black flex w-full justify-center text-[white]">
      <div className="max-w-[800px] text-sm p-10 mb-5">
        <div className={header}>Unpacking Impact</div>
        <div className="font-bold">
          As someone with white, thin, phsyically-able bodied, European-American
          privilege:
        </div>
        <div className="mt-2">
          Internalized supremacist bias and colonizer conditioning provides the
          option to avoid crucial, relational-mending conversations (that might
          otherwise be inherent to someone else's existence). This degree of
          selective ignorance creates a substantial barrier for collective
          healing.
        </div>
        <div className="mt-2">
          Further,{" "}
          <span className="font-bold">
            I cannot understand the impact of ancestral, generational, and
            systemic trauma that accompanies being a person of color nor the
            byproduct of intentional systemic annihaltion due to race and/or
            heritage.
          </span>{" "}
          There is trauma I do not and won't ever know.
        </div>{" "}
        <div className="mt-2">
          Additionally, as someone who works with our Earth as a healing
          resource, recognizing the history embued in the soil of that land is a
          key aspect of honoring its contributions. Meaning, when I offer
          support in the now referenced city of Houston,{" "}
          <span className="font-bold">
            I am practicing on the native lands of the Coahuiltecan, Karankawa,
            Sana, Ishak (Atakapa), Atakapa, and Karankawa people
          </span>
          . Their lives on this land are a presence that cannot be erased.
        </div>
        <div className="mt-2">
          In my practice understanding someone else’s suffering is not a
          prerequisite for offering and providing care.{" "}
          <span className="font-bold">
            Education, respect, active empathy, reflection, and responsibility
            for the role I play in the revolution of healing— influence how I
            show up for the people who need and seek my help.
          </span>
        </div>
        <div className="mt-2">
          Equally, this may mean I am not the clinician for you. AND THAT'S OKAY.
          <div className="mt-2 underline">What we can do, is get you the community resources, support, and safety you
          have deserved from the beginning.</div>
        </div>
        {/* <div className="mt-2">
          The open and active wounds of our community do not heal in perfection
          or isolation but in showing up, opening myself to the risk of being
          wrong, and allowing each other's experiences to change one another into
          champions for each other.
        </div>
        <div className="mt-2">
          Because what affects you, affects me, affects all. We are not
          separate.{" "}
          <span className="font-bold">
            And you (your life, your healing, your heart, your ideas, your
            story, your spirit) matter just as much as anyone and everything
            else.
          </span>
        </div> */}
        
      </div>
    </div>
  );
}

// It as my responsiblity as to ensure my social, cultural, and environmental
// conditionings are saught, held, and addressed. They are for me to
// stop.

// My goal for our
// relationship is, first, safety. It is, at a very minimum, my
// responsibilty to earn your trust, respect who you are, and offer you
// the grace and diginity your life deserves.

// Privilege means I know greater freedom of choice,
//             increased access to resources, and security in situations that might
//             otherwise be unsafe to someone else.  Carrying privilege as a result of heritage, skin, economic standing, appearance, and ability means there is bone and blood deep suffering I do
//             not know. However, you will never be made
//             responsible for describing your marginalization and oppression
//             unless it is something you want to share.
