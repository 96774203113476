export default function Tools() {
  return (
    <>
      With all seekers, I use a western, therapeutic-style talk session to
      establish safety first. This is the foundation of any and all work we do
      together and it is imperative that this seedling of trust is felt to your
      available capacity so that you may feel available to the work to come. From
      this more eurocentric approach, I like to source support from my global
      learnings and ancestoral wisdom. This can look like pulling additional
      care or insight from:
      <div className="mt-4 font-bold">
        <li>Tarot</li>
        <li>Meditation or visualization exercises</li>
        <li>Personal energy clearings</li>
        <li>Angel, guardians, or energetic supports</li>
        <li>Safe body movement</li>
        <li>Coregulation with Nature</li>
        <li>Safety and symbolism with animals</li>
      </div>
      <div className="mt-4">
        I also like to welcome in and cocreate honoring space for your{" "}
        <span className="font-bold">
          specific ancestoral heritage and cultural healing tools
        </span>
        — of which we can find or create a path to if it feels lost but longed
        for.
      </div>
    </>
  );
}
