import React from "react";
// import FlipText from "./FlipText";
import Boxes from "./Boxes";
import Principles from "./Principles";
import WorkWithMe from "./WorkWithMe";
import ReducedRate from "./ReducedRate";

export default function Services() {
  return (
    <div className="flex flex-col py-10">
      <Principles />
      <Boxes />
      <WorkWithMe />
      <ReducedRate />
    </div>
  );
}

export const section = "flex items-center justify-center h-[500px] last:mb-0";
export const header = "capitalize font-bold text-4xl mb-8";


  /* I believe only
then is there room to explore avenues for integrating the past,
grounding into the present, and building a future where living is the
gift. */

