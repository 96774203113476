export default function WhoIHelp() {
  return (
    <>
      I specialize in reclamation of the self through creative expression,
      spirituality, and natural world connecting. My focus is working with
      dreamers, survivors, creators, healers, advocates, artists, activists, the
      highly sensitive, the stubbornly hopeful, the societally resistant, the
      individually mutuable, and inquisitives who ask why, what's the point,
      what now. I help those who seek:
      <div className="my-4 font-bold">
        <li className="mb-2">who they are</li>
        <li className="mb-2">rebirth and reclaimation</li>
        <li className="mb-2">hope for tomorrow</li>
        <li className="mb-2">connection to their spirit</li>
        <li className="mb-2">peace with what has passed</li>
        <li className="mb-2">trust in the unconditional</li>
        <li className="mb-2">externalization of the world inside</li>
        <li className="mb-2">ownership of their power</li>
      </div>
    </>
  );
}
