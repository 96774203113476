import { header } from "../index";

export default function Achievements() {
  return (
    <div className={section}>
      <div className={img}>
        <div className="bg-biz-card bg-cover h-full w-full"></div>
      </div>
      <div className={items + " p-10"}>
        <div className={header}>Credentials</div>
        <div className="flex">
        <div className={items + " mr-4"}>
        {row1.map((a) => {
          return (
            <div className={box}>
              <div className="flex flex-col flex-wrap flex-end">
                <div className={boxTitre}>{a.name}</div>
                <div className={boxSousTitre}>{a.detail}</div>
              </div>
            </div>
          );
        })}
        </div>
        <div className={items}>
         {row2.map((a) => {
          return (
            <div className={box}>
              <div className="flex flex-col flex-wrap flex-end">
                <div className={boxTitre}>{a.name}</div>
                <div className={boxSousTitre}>{a.detail}</div>
              </div>
            </div>
          );
        })}
        </div>
        </div>
      </div>
    </div>
  );
}

const section = "flex h-auto";
const img = "w-1/2 h-auto md:block hidden";
const items = "flex md:w-1/2 bg-black text-[white] flex-col items-center";

const box = "w-full flex items-center pb-8 max-w-[250px]";
const boxTitre = "font-bold mb-2 text-xs";
const boxSousTitre = "italic text-xs";

const row1 = [
  {
    name: "Chill Counseling Counselor Intern",
    detail: "Supervised by Cori Hill, LPC LMFT"
  },
  {
    name: "Thematic Lab with Stanley Ollivier on Energy & Care",
    detail: "P.A.R.T.S Summer Residency in Belgium"
  },
  {
    name: "Individual Foundations of Expressive Arts Therapy 1 & 2",
    detail: "Center For Creative Arts Therapy"
  },
  {
    name: "Training Acceptance",
    detail: "Somatic Experiencing® International "
  },
  {
    name: "Mandated Reporter for Mental Health Professionals",
    detail: "California Department of Social Services"
  },
  
];

const row2 = [
  {
    name: "Masters in Clinical Mental Health Counseling",
    detail: "Palo Alto University, Jun 2024"
  },
  
  {
    name: "Recovery Mentor & Program Co-Creator",
    detail: "Healing For Eating Disorders"
  },
  {
    name: "Dance Therapy Educator",
    detail: "The Monarch Institute"
  },
  {
    name: "Professional Movement Artist",
    detail: "Freelance, LEON Contemporary, Revolve Dance Company"
  },
  {
    name: "Integrative Neurolinguistic Programming (iNLP) Practitioner",
    detail: "The Empowerment Partnership"
  },
]
