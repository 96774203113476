import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
// import Disclaimer from "./Disclaimer";
import Tools from "./Tools";
import WhoIHelp from "./WhoIHelp";
import Pricing from "./Pricing";
import { header } from "../index";
import { secondaryBtn } from "../../../components/constants";

const cardsRow1 = [
  {
    num: "01",
    cardTitle: "Who I Help",
    body: <WhoIHelp />,
    left: true,
  },
  {
    num: "02",
    cardTitle: "What Happens",
    body: <Tools />,
    left: true,
  },
];

const pricing = {
  num: "03",
  cardTitle: "Session Type & Pricing",
  body: <Pricing />,
  left: true,
};
// const cardsRow2 = [
//   {
//     num: "04",
//     cardTitle: "Disclaimer.",
//     body: <Disclaimer />,
//     left: false,
//   },
// ];

export default function Boxes() {
  return (
    <>
      <div className="w-full flex flex-wrap justify-between">
        {cardsRow1.map((card) => (
          <div className={body + " md:w-[48%]"}>
            <div className={number}>{card.num}</div>
            <div className={header + " rounded-full"}>{card.cardTitle}</div>
            <div className="text-md">{card.body}</div>
          </div>
        ))}
      </div>

      <div className={"flex flex-col w-full p-10 my-10 bg-black text-[white]"}>
        <div className={number + " text-creme/20"}>{pricing.num}</div>
        <div className={header + " rounded-full"}>{pricing.cardTitle}</div>
        <div className="text-md">{pricing.body}</div>
        <div className="flex w-full justify-center">
            <div className={secondaryBtn + " w-[400px]"}>
              <Link to={ROUTES.contact}>Book Free 15-Min Consultation</Link>
            </div>
          </div>
      </div>
    </>
  );
}

export const number = "font-bold text-gray text-[100px] text-[#0000000f] ";
export const sideLabel =
  " h-full flex flex-col justify-center px-10 justify-center mt-[120px]";
export const body = "flex flex-col w-full p-10 my-10 text-black bg-creme";
