import React from "react";
import {
  mentalhealth,
  texas,
  us,
  tech,
  funds,
  lgbtq,
  movement,
  articles,
  france,
  relationships,
  books,
  education,
  intimacy,
  nonmonog,
  communication,
  action,
  westmodalities,
  quizzes,
  sasurvivors,
  games,
  crisis,
  inperson,
  online,
} from "./constants";

const Items = ({ items, title }) => {
  return (
    <div className="flex flex-col mb-8 md:mr-8 md:w-[300px]">
      <div className="font-bold text-2xl">{title}</div>
      {items.map((item) => (
        <a href={item.link} rel="noreferrer" target="_blank" key={item.title}>
          <span className="underline">{item.title}</span>
        </a>
      ))}
    </div>
  );
};

export default function Resources() {
  return (
    <div className="flex flex-wrap items-start w-full md:py-10 pb-10">
      <div className="flex flex-col justify-center">
        <div className="font-bold text-4xl mb-4 border-b-2 md:mr-10">Emergency</div>
        <Items items={texas} title={"Texas Hotlines"} />
        <Items items={us} title={"United States Hotlines"} />
        <Items items={france} title={"France Hotlines"} />
      </div>
      <div className="flex flex-col justify-center">
        <div className="font-bold text-4xl mb-4 border-b-2 md:mr-10">Community Care</div>
        <Items items={lgbtq} title={"LGBTQ+ Services"} />
        <Items items={crisis} title={"Crisis"} />
        <Items items={education} title={"Education"} />
        <Items items={action} title={"Action"} />
      </div>
      <div className="flex flex-col justify-center">
        <div className="font-bold text-4xl mb-4 border-b-2 md:mr-10">Trauma</div>
        <Items items={relationships} title={"Physical Safety"} />
        <Items items={sasurvivors} title={"Survivors"} />
        <Items items={westmodalities} title={"Western Modalities"} />
      </div>
      <div className="flex flex-col justify-center">
        <div className="font-bold text-4xl mb-4 border-b-2 md:mr-10">Relationships</div>
        <Items items={intimacy} title={"Sex & Intimacy"} />
        <Items items={communication} title={"Communication"} />
        <Items items={quizzes} title={"Quizzes"} />
        <Items items={nonmonog} title={"ENM & Polyamory"} />
      </div>
      <div className="flex flex-col justify-center">
        <div className="font-bold text-4xl mb-4 border-b-2 md:mr-10">Groups</div>
        <Items items={inperson} title={"In-Person (Houston)"} />
        <Items items={online} title={"Online (Texas)"} />
      </div>
      <div className="flex flex-col justify-center">
        <div className="font-bold text-4xl mb-4 border-b-2 md:mr-10">At-Home</div>
        <Items items={articles} title={"Articles"} />
        <Items items={books} title={"Books"} />
        <Items items={movement} title={"Somatic Pratices"} />
        <Items items={games} title={"Games"} />
      </div>
      <div className="flex flex-col justify-center">
        <div className="font-bold text-4xl mb-4 border-b-2 md:mr-10">Additional Support</div>
        <Items items={funds} title={"Funding"} />
        <Items items={tech} title={"Technology"} />
        <Items items={mentalhealth} title={"Therapy Directories"} />
        {/* <Items items={referrals} title={"Referrals"} /> */}
      </div>
    </div>
  );
}

// <div className="flex flex-col mb-8">
// <div className="font-bold text-2xl mb-2">My Articles & Research</div>
// <a
//   className="w-[400px] "
//   rel="noreferrer"
//   target="_blank"
//   href="https://chillcounseling.com/2023/10/31/climate-changes-silent-impact-seasons-mental-health-and-you/"
// >
//   2023.10.03{" "}
//   <strong>
//     Climate Change’s Silent Impact: Seasons, Mental Health, and You
//   </strong>
// </a>
// <a
//   className="w-[400px] mt-2"
//   rel="noreferrer"
//   target="_blank"
//   href="https://drive.google.com/file/d/1g7HYrjg8tTC4ISOJqIMgXTWulO5yXbDT/view"
// >
//   2021.07.16{" "}
//   <strong>
//     Music, Dance, and Visual Art Therapies: How the Application of
//     Process Over Product Can Access Meaning
//   </strong>
// </a>
// </div>
