import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ClickAwayListener, Drawer } from "@mui/material";
import { Close } from "@mui/icons-material";
import Social from "../Social";
import menuIcon from "@assets/mobile-menu.png";
import { ROUTES, routeTree } from "@routes";
import clsx from "clsx";

const LinkItem = ({ item }) => {
  return (
  <Link
      className={clsx(
        item.parent ? "font-bold pt-8 uppercase" : "font-thin capitalize",
        "text-xl pt-4 pb-1 font-sans"
      )}
      to={item.link}
    >
      {item.parent ? `${item.display} —------` : item.display}

      <div className="flex ml-4 flex-col">
        {item.subLinks?.map((sub, i) => (
          <LinkItem item={sub} key={i} />
        ))}
      </div>
    </Link>
  )};

export default function Sidebar() {
  const [navBarOpen, toggleNavBar] = useState(false);

  const open = () => toggleNavBar(true);
  const close = () => toggleNavBar(false);

  return (
    <div className="flex my-2 mx-4 relative w-full font-sans">
      <Link to={ROUTES.home} className={siteName}>
          Moonflamelove.
        </Link>
      <div onClick={open} className="h-[20px] absolute right-0 cursor-pointer">
        <img
          src={menuIcon}
          className="h-full w-10 mt-3"
          alt="dark moon flame love logo"
        />
      </div>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            height: "100%",
            border: "none",
            borderTopLeftRadius: 40,
            borderBottomLeftRadius: 40,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="right"
        open={navBarOpen}
      >
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={close}
        >
          <div className="h-full w-full flex flex-col bg-black text-[white] absolute right-0 top-0 rounded-bl-[40px] rounded-tl-[40px] px-8">
            <div className="w-full flex justify-end mt-4 cursor-pointer">
              <Close fontSize="medium" onClick={close} />
            </div>
            {routeTree.map((route, i) => (
              <LinkItem item={route} key={i} />
            ))}
            <div className="absolute bottom-0 w-full flex justify-evenly mb-8 -ml-8">
              <Social size={"lg"} color={"white"} />
            </div>
          </div>
        </ClickAwayListener>
      </Drawer>
    </div>
  );
}

const siteName = "font-bold text-2xl mb-2 mt-2";