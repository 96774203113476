import React from "react";
import Sidebar from './Sidebar'
import Header from './Header'

export default function Nav() {
  return (
    <>
      <div className="hidden lg:flex"><Header /></div>
      <div className="flex lg:hidden"><Sidebar /></div>
    </>
  );
}
