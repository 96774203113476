export const texas = [
  { title: "Domestic Violence Hotline", desc: "800-799-7233", link: "" },
  {
    title: "Texas 211",
    desc: "Free 24hr hotline for statewide resources, shelters, and protection options",
    link: "",
  },
  {
    title: "Texas Advocacy Project",
    desc: "800-374-4673 (Mon-Fri 9am-5pm) domestic violence, stalking, SA",
    link: "",
  },
];

export const books = [
  {
    title: "Digital Copy of ACA Big Red Book",
    link: "https://drive.google.com/file/d/1VKOtjE0dcnOEEgtjUUG1R9HCTDFyZzVZ/view?pli=1",
  },
  {
    title: "Code of Ethics (+ Client Rights)",
    link: "https://www.counseling.org/docs/default-source/ethics/2014-aca-code-of-ethics.pdf",
  },
  {
    title: "Radical Acceptance",
    link: "https://www.goodreads.com/book/show/213181082-radical-acceptance?ref=nav_sb_ss_1_18",
  },
  {
    title: "When Things Fall Apart",
    link: "https://www.goodreads.com/book/show/687278.When_Things_Fall_Apart?ref=nav_sb_ss_1_12",
  },
];

export const relationships = [
  {
    title: "Secure App for Creating Escape Plan",
    link: "https://myplanapp.org/",
  },
  {
    title: "All-Bodies Online Self-Defense",
    link: "https://forge-forward.org/resource/trans-self-defense-and-empowerment-for-all-bodies/",
  },
  {
    title: "Callisto - Campus SA Reporting",
    link: "https://www.projectcallisto.org/",
  },
  {
    title: "Texas State Law Defining: Consent",
    link: "https://a.trainingcdn.com/sl-slic/automated/slic-interactions/JEDUCPN-00107/Alcohol_and_Coercion/v1/assets/eng/pages/media/pdfs/State_Law_Consent-Texas.pdf",
  },
  {
    title: "Texas State Law Defining: Stalking",
    link: "https://a.trainingcdn.com/sl-slic/automated/slic-interactions/JEDUCPN-00107/Not_So_Chance_Encounter/v1/assets/eng/pages/media/pdfs/Texas.pdf",
  },
  {
    title: "Texas State Law Defining: SA",
    link: "https://a.trainingcdn.com/sl-slic/automated/slic-interactions/JEDUCPN-00107/Alcohol_and_Coercion/v1/assets/eng/pages/media/pdfs/Federal_and_State_Laws_Sexual_Assault-Texas.pdf",
  },
];

export const us = [
  {
    title: "Crisis Text Line",
    desc: "provides free, 24/7 support via text message. They're here for everything: anxiety, depression, suicide, school. Text HOME to 741741.",
    link: "https://www.crisistextline.org/",
  },
  {
    title: "Blackline",
    desc: "Crisis hotline for BIPOC. Call or text 800-604-5841.",
    link: "https://www.callblackline.com/",
  },
  {
    title: "Trans Lifeline",
    desc: "Call or text 877-565-8860 in the US, and 877-330-6366 in Canada.",
    link: "https://translifeline.org/",
  },
  {
    title: "Trevor Hotline",
    desc: "",
    link: "https://www.thetrevorproject.org/get-help/",
  },
  {
    title: "RAINN: National SA Hotline",
    desc: "",
    link: "https://hotline.rainn.org/online",
  },
  {
    title: "Suicide & Crisis Lifeline",
    desc: "Suicide and Crisis Lifeline, available 24/7 in English and Spanish.",
    link: "https://988lifeline.org/",
  },
  {
    title: "More crisis resources",
    desc: "",
    link: "https://www.inclusivetherapists.com/contact#crisis",
  },
];

export const france = [
  {
    title: "Europe-wide Emergency Number",
    link: "tel:+33112",
  },
  {
    title: "Child Abuse Hotline",
    link: "tel:+33119",
  },
  {
    title: "Domestic Violence Helpline",
    link: "tel:+333919",
  },
  {
    title: "SA Hotline",
    link: "tel:+330800059595",
  },
  {
    title: "Racial Discrimination Helpline",
    link: "tel:+33114",
  },
  {
    title: "Terror/Kidnapping Hotline",
    link: "tel:+33197",
  },
  {
    title: "Plus de services de la santé et sécurité",
    link: "https://www.expatica.com/fr/healthcare/healthcare-basics/french-emergency-numbers-101100/#mental-health",
  },
];
export const tech = [
  {
    title: "Device Settings For Partner Safety",
    link: "https://myplanapp.org/en/techsafety",
  },
  {
    title: "Setting Up For Your Telehealth Appt",
    link: "https://import.cdn.thinkific.com/316832/clientdirectionsfortelehealth-201208-221815.pdf",
  },
  {
    title: "Affordable Connectivity Program",
    link: "https://www.fcc.gov/acp",
  },
  {
    title: "Xfinity ACP Program",
    link: "https://www.xfinity.com/learn/internet-service/acp",
  },
  {
    title: "BackMarket",
    link: "https://www.backmarket.com/",
  },
];
export const funds = [
  {
    title: "Black Mental Health Fund",
    desc: "",
    link: "https://mentalhealthliberation.org/black-mental-health-fund-easy-activism/",
  },
  {
    title: "Palestinian Mental Health Fund",
    desc: "",
    link: "https://saneinthemembrane.com/palestinian-mh-fund",
  },
  {
    title: "Therapy Dinero Grant",
    desc: "",
    link: "https://alejandrefoundation.org/steps-for-applicants/",
  },
  {
    title: "BIPOC Therpay Fund",
    desc: "",
    link: "https://mentalhealthliberation.org/bipoc-therapy-fund/",
  },
  {
    title: "Houston BIPOC Arts Network & Fund",
    desc: "",
    link: "https://houstonbanf.org/",
  },
  {
    title: "Rauschenberg Dancer Emergency Grants",
    desc: "",
    link: "https://www.nyfa.org/awards-grants/rauschenberg-emergency-grants/rauschenberg-dancer-emergency-grants/",
  },
];
export const mentalhealth = [
  {
    title: "Open Path",
    desc: "Accessible therapy rates through sliding scale and fixed rates",
    link: "",
  },
  {
    title: "Inclusive Therapists",
    desc: "Clinician database with focus on underserved, oppressed, and/or mariginalized populations",
    link: "",
  },
  {
    title: "Chill Counseling",
    desc: "Virtual BIPOC Gender-Expansive Private Practice in Texas",
    link: "http://chillcounseling.com",
  },
  {
    title: "More general & community-specific",
    desc: "Broad stroke clinician search with speciality, focus, and location filters",
    link: "https://www.counseling.org/aca-community/learn-about-counseling/what-is-counseling/find-a-counselor",
  },
];

export const articles = [
  {
    title: "Routes of Safety: How To Access Safety After Trauma",
    link: "https://www.mswjake.com/_files/ugd/a4427e_82994c3ea81e45d7af18908c58dee2e1.pdf",
  },
  {
    title: "Difference Between Stress & Trauma",
    link: "https://www.mswjake.com/_files/ugd/a4427e_a69390dc51be4f5ea88cd331f1cbab87.pdf",
  },
  {
    title: "How To Practice Feeling Emotions In Your Body",
    link: "https://www.mswjake.com/_files/ugd/a4427e_3553131342da495fa9e617110117d5b5.pdf",
  },
  {
    title: "Grounding Techniques 101",
    link: "https://www.beautyafterbruises.org/blog/grounding101",
  },
  {
    title: "DID Myths & Misconceptions",
    link: "https://www.beautyafterbruises.org/blog/didmyths",
  },
  
];

export const education = [
  {
    title: "Native US Land",
    link: "https://native-land.ca/",
  },
  {
    title: "Braiding Sweetgrass",
    link: "https://www.goodreads.com/book/show/17465709-braiding-sweetgrass",
  },
  {
    title: "Beyond The Gender Binary",
    link: "https://www.goodreads.com/book/show/51794301-beyond-the-gender-binary?ref=nav_sb_ss_1_24",
  },
  {
    title: "Fearing the Black Body: The Racial Origins of Fat Phobia",
    link: "https://www.goodreads.com/book/show/42129163-fearing-the-black-body?ref=nav_sb_ss_1_22",
  },
  {
    title: "Post Traumatic Slave Syndrome",
    link: "https://www.goodreads.com/book/show/432113.Post_Traumatic_Slave_Syndrome?ref=nav_sb_ss_1_18",
  },
]

export const games = [
  {
    title: "We're Not Really Strangers",
    link: "https://www.werenotreallystrangers.com/collections/games",
  },
]

export const intimacy = [
  {
    title: "Shrimp Teeth: Sex and Relationship Education for Queer & Non-Monogamous Adults",
    link: "https://shrimpteeth.com/home",
  },
  {
    title: "Will Want Won't Worksheet",
    link: "https://drive.google.com/file/d/1brrGcvfHNjfCjuotBSNZlCR_wIPMmkAC/view?usp=sharing",
  },
  {
    title: "Come As You Are",
    link: "https://www.goodreads.com/book/show/22609341-come-as-you-are?ref=nav_sb_ss_1_12",
  },
]

export const sasurvivors = [
  {
    title: "When the Person You Love Was Sexually Abused as a Child",
    link: "https://www.goodreads.com/book/show/354716.Allies_in_Healing?ref=nav_sb_ss_1_17",
  },
  {
    title: "The Sexual Healing Journey",
    link: "https://www.goodreads.com/book/show/242410.The_Sexual_Healing_Journey?ref=nav_sb_ss_1_22",
  },
  {
    title: "What's Ok? Text Line",
    link: "https://www.whatsok.org/howitworks",
  },
  {
    title: "Incest AWARE: Services for Adult & Child Survivors",
    link: "https://www.incestaware.org/incest-survivors-support",
  },
]

export const quizzes = [
  {
    title: "Love Style",
    link: "https://howwelove.com/love-style-quiz/",
  },
  {
    title: "Love Language",
    link: "https://5lovelanguages.com/quizzes/love-language",
  },
  {
    title: "Attachment Style",
    link: "https://www.attachmentproject.com/attachment-style-quiz/",
  },
  {
    title: "Personality in Attachment",
    link: "https://yourpersonality.net/attachment/",
  },
]
export const communication = [
  {
    title: "Relationship Agreement",
    link: "https://drive.google.com/file/d/1Ro7a5Yg-TecYxfyp0iqjVdiund-At59H/view?usp=sharing",
  },
  {
    title: "Recognizing Defensive Patterns",
    link: "https://positive.b-cdn.net/wp-content/uploads/2022/01/Recognizing-Defensive-Patterns.pdf",
  },
  {
    title: "Identifying Needs",
    link: "https://positive.b-cdn.net/wp-content/uploads/2022/03/Identifying-Needs-And-Wants.pdf",
  },
  {
    title: "Love More, Fight Less",
    link: "https://www.goodreads.com/book/show/53474716-love-more-fight-less?ref=nav_sb_noss_l_20",
  },

]
export const referrals = [
  {
    title: "Tammi | CA | Child, Teen",
    link: "",
  },
  {
    title: "Rachel | CO | Trauma, OCD",
    link: "",
  },
  {
    title: "Victoria | CA | Formerly Incarerated",
    link: "",
  },
  {
    title: "Iman | CA | Palestine, Blind",
    link: "",
  },
]
export const westmodalities = [
  {
    title: "EMDR",
    link: "https://www.emdr.com/what-is-emdr/#layperson",
  },
  {
    title: "Somatic Experiencing",
    link: "https://traumahealing.org/se-101/",
  },
  {
    title: "Brainspotting",
    link: "https://brainspotting.com/about-bsp/",
  },
  {
    title: "Internal Family Systems",
    link: "https://ifs-institute.com/",
  },
  {
    title: "Ketamine-Assisted",
    link: "https://www.pacificneuroscienceinstitute.org/blog/trip/what-is-ketamine-therapy/",
  },
]

export const nonmonog = [
  {
    title: "Relationship Anarchy",
    link: "https://relationship-anarchy.com/about/",
  },
  {
    title: "Non-Monogamoy for the Anxiously Attached",
    link: "https://www.goodreads.com/book/show/60762262-the-anxious-person-s-guide-to-non-monogamy?ac=1&from_search=true&qid=KfeQtPcori&rank=1",
  },
  {
    title: "Multiamory Podcast",
    link: "https://www.multiamory.com/podcast#gsc.tab=0",
  },
]

export const crisis = [
  {
    title: "Free Therapy by #ActionForPalestine",
    link: "https://www.ruhcare.com/palestine",
  },
]

export const action = [
  {
    title: "How to Vote in Texas",
    link: "https://www.votetexas.gov/",
  },
  {
    title: "Places to Volunteer in Houston",
    link: "https://local.aarp.org/houston-tx/volunteering/?cmp=CSN-KNC-AARPLOCAL-ECPPAIDSEARCH-HOUSTON-2024-GOOGLE&gad_source=1&gclid=CjwKCAjwjqWzBhAqEiwAQmtgTyAAnfhqFvNEOdLtGWeymP1LuNj0oC6Wdd4pLbkCL3feH5HPdqivERoC9VcQAvD_BwE&gclsrc=aw.ds",
  },
  {
    title: "Find a Protest",
    link: "https://medium.com/swlh/this-is-how-you-find-protests-in-your-city-cfcbf9d48c8f#id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImMzYWJlNDEzYjIyNjhhZTk3NjQ1OGM4MmMxNTE3OTU0N2U5NzUyN2UiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIyMTYyOTYwMzU4MzQtazFrNnFlMDYwczJ0cDJhMmphbTRsamRjbXMwMHN0dGcuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyMTYyOTYwMzU4MzQtazFrNnFlMDYwczJ0cDJhMmphbTRsamRjbXMwMHN0dGcuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDE1NjYxNjU5MzIwMzg2NDY1OTAiLCJlbWFpbCI6InZpb2xldC53bW9vbkBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzE4MjE5Nzk4LCJuYW1lIjoiVmlvbGV0IE1vb24iLCJnaXZlbl9uYW1lIjoiVmlvbGV0IiwiZmFtaWx5X25hbWUiOiJNb29uIiwiaWF0IjoxNzE4MjIwMDk4LCJleHAiOjE3MTgyMjM2OTgsImp0aSI6IjYyNTQ1NzMzNjI3M2EwN2Q2OTAxZjE1NDkxODZkNWQ2NzkyMGQxNjUifQ.ppz9-YlAJa23MxjfB9k0MdFd-Rsze75vicsNusK9VVVdBWkOdD3KN12R0muloRVCT2H-_ho6eLyKdhhPBmq1_Ul-ptXG54Flfgz8aBYLtaXw-p3Tc6lPKy8Ez-818IDbWNQ5AcONO_NWsqA_eWL_g4YiSpbjuqI8ow_usNQPVhWanAICtCkerwuVPNfqMrwa2laAL16cGgqy-EsFIWTW4JL30tmFDs3UrMBg0APRne61eDawIbIwsTWtZaK0-X6cQH0wRx4nqExHilpnWXA0TcAVC5t6nKTQONPzDy847T6Ew49zvskkiXf-foIoHFfrVe7gv5uvr7k97zpBDS7lbw",
  },
  {
    title: "How to Contact Your Representative",
    link: "https://www.house.gov/representatives/find-your-representative",
  },
  {
    title: "Find a Cause",
    link: "https://study.com/academy/lesson/social-issues-definition-examples.html",
  },
]
export const inperson = [
  {
    title: "Queer Domestic Violence Survivors",
    link: "https://montrosecenter.org/services/counseling/gp-therapy/domesticviolencegroup/",
  },
  {
    title: "LGBTQ Polyamory Peer Group",
    link: "https://montrosecenter.org/community-center/blog/polyamory-peer-group/",
  },
  {
    title: "LGBT HIV+ Support",
    link: "https://montrosecenter.org/services/counseling/gp-therapy/lgbt-hiv-support-group/",
  },
]
export const online = [
  {
    title: "Beyond The Binary",
    link: "https://montrosecenter.org/community-center/blog/beyond-the-binary/",
  },
  {
    title: "Coming Out",
    link: "https://montrosecenter.org/community-center/blog/comingout/",
  },
  {
    title: "Trans*Fabulous",
    link: "https://montrosecenter.org/community-center/blog/transfabulous-support-group/",
  },
  {
    title: "Houston Gay and Lesbian Parents (HGLP)",
    link: "https://www.facebook.com/groups/hglparents/",
  },
]
export const lgbtq = [
  {
    title: "Resource Center Dallas",
    desc: "Gender-affirming services, treatment, and therapy",
    link: "https://myresourcecenter.org/health/gender-affirming-services/",
  },
  {
    title: "Online US-Based HRT",
    link: "https://getplume.co/transgender-hrt-doctors-online/",
  },
  {
    title: "Centre LGBTQ+ Paris",
    link: "https://www.centrelgbtparis.org/",
  },
  {
    title: "Transgender-Inclusive Houston Endrocrinology",
    link: "https://getplume.co/transgender-hrt-doctors-online/",
  },
  {
    title: "Free HIV/STD Testing in Houston",
    link: "https://www.legacycommunityhealth.org/services/hivstd-screening-treatment/",
  },
];
export const movement = [
  {
    title: "Free Yoga Library — DoYogaWithMe",
    desc: "",
    link: "https://www.doyogawithme.com/yoga-classes",
  },
  {
    title: "Free Online Yoga — Kassandra",
    desc: "",
    link: "https://www.youtube.com/@yogawithkassandra",
  },
];
